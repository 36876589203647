<template>
  <div>
    <b-form>
      <b-row>
        <b-col class="col-sm-12">
          <b-card>
          <br />
          <b-col>
          <!-- <b-form-group label-cols-md="4" label="Kendaraan">
            <v-select
              v-model="fieldForm.variant"
              label="name"
              placeholder="Pilih NOPOL Kendaraan"
              :options="optionVariant"
              @input="changeSelectVariant"
            />
            
          </b-form-group> -->
          <form-autocomplate
            label="Kendaraan"
            placeholder="Masukkan Nopol Kendaraan"
            :min-length="1"
            index-value="number"
            dataUrl="/vehicle?search="
            objectView="data"
            @change="changeInput"
          />
          <!-- <form-radio
            v-model="fieldForm.guarantor_type"
            @input="changeGuarantor"
            label="Penanggung Jawab"
            rules=""
            :options="[
              {name: 'Asuransi', id: 'assurance'},
              {name: 'Pengguna', id: 'impactor'},
            ]"
          />
          <hr>
          </b-col>
          <b-col v-if="fieldForm.guarantor_type == 'assurance'">
          <h4>Data Asuransi</h4>
          <form-input
            v-model="assurance.name"
            rules="required"
            label="Nama Asuransi"
            placeholder="Nama Asuransi"
          />
          <form-input
            v-model="assurance.address"
            rules="required"
            label="Alamat Asuransi"
            placeholder="Alamat Asuransi"
          />
          <form-input
            v-model="assurance.no_telp_hp"
            rules="required"
            label="No. Telp / HP"
            placeholder="No. Telp / HP"
          />
          <form-input
            v-model="assurance.email"
            rules="required"
            label="Email"
            placeholder="Email"
          />
          <hr>
          </b-col>
          <b-col v-if="fieldForm.guarantor_type == 'impactor'">
          <h4>Data Pengguna</h4>
          <form-input
            v-model="impactor.name"
            rules="required"
            label="Nama Pengguna"
            placeholder="Nama Pengguna"
          />
          <form-input
            v-model="impactor.address"
            rules="required"
            label="Alamat Pengguna"
            placeholder="Alamat Pengguna"
          />
          <form-input
            v-model="impactor.no_ktp_sim"
            rules="required"
            label="No. KTP/ SIM"
            placeholder="No. KTP/ SIM"
          />
          <form-input
            v-model="impactor.no_telp_hp"
            rules="required"
            label="No. Telp / HP"
            placeholder="No. Telp / HP"
          />
          <form-input
            v-model="impactor.number_plate"
            rules="required"
            label="NOPOL Kendaraan"
            placeholder="NOPOL Kendaraan"
          />
          <form-input
            v-model="impactor.merk"
            rules="required"
            label="Merk / Type Kendaraan"
            placeholder="Merk / Type Kendaraan"
          />
          <form-input
            v-model="impactor.tahun"
            rules="required"
            label="Tahun Kendaraan"
            placeholder="Tahun Kendaraan"
          />
          <form-input
            v-model="impactor.warna"
            rules="required"
            label="Warna Kendaraan"
            placeholder="Warna Kendaraan"
          /> -->
          <hr>
          </b-col>
          <b-col>
          <h4>Data Kerusakan Kendaraan</h4>
          <!-- <form-input
            v-model="fieldForm.company_name"
            rules="required"
            label="Nama Perusahaan"
            placeholder="Nama Perusahaan"
          />
          <form-input
            v-model="fieldForm.company_address"
            rules="required"
            label="Alamat Perusahaan"
            placeholder="Alamat Perusahaan"
          /> -->
          <form-input
            v-model="fieldForm.name"
            rules="required"
            label="Nama Pengemudi"
            placeholder="Nama Pengemudi"
          />
          <form-input
            v-model="fieldForm.phone"
            rules="required"
            label="No Handphone"
            placeholder="No Handphone"
          />
          <form-input
            v-model="fieldForm.nik"
            rules="required"
            label="NIP"
            placeholder="NIP"
          />
          <form-input
            v-model="fieldForm.sim"
            rules="required"
            label="No SIM"
            placeholder="No SIM"
          />
          <form-input
            v-model="fieldForm.email"
            rules="required"
            label="Email"
            placeholder="Email"
          />
          <form-input
            v-model="fieldForm.address"
            rules="required"
            label="Alamat"
            placeholder="Alamat Pengemudi"
          />
          <form-input
            v-model="fieldForm.subject"
            rules="required"
            label="Judul Isu"
            placeholder="Judul Isu"
          />
          <!-- <form-input
            v-model="fieldForm.date"
            rules="required"
            type="date"
            label="Tanggal"
            placeholder="Tanggal Kejadian"
          /> -->
          <b-form-group label-cols-md="4" label="Tanggal Kejadian">
            <flat-pickr
              v-model="fieldForm.date"
              class="form-control"
              placeholder="Tanggal Kejadian"
            />
          </b-form-group>
          <b-form-group label-cols-md="4" label="KM ODOmeter">
            <cleave
              v-model="fieldForm.odometer"
              class="form-control"
              :raw="true"
              :options="options.number"
              placeholder="Isi KM yang tampak di ODOmeter"
              rules="required"
            />
          </b-form-group>
          <!-- <form-input
            v-model="fieldForm.odometer"
            rules="required"
            type="number"
            label="KM ODOmeter"
            placeholder="Isi KM yang tampak di ODOmeter"
          /> -->
          <form-input
            v-model="fieldForm.issue_address"
            rules="required"
            label="Lokasi Kejadian"
            placeholder="Lokasi Kejadian"
          />
          <b-form-group label-cols-md="4" label="Kronologi Kejadian">
            <b-form-textarea
              v-model="fieldForm.description"
              rules="required"
              placeholder="Jelaskan kronologi dari isu kendaraan"
              rows="3"
            />
          </b-form-group>
          <b-form-group label-cols-md="4" label="Foto Odometer">
            <b-form-file
              id="extension1"
              v-model="datafile1"
              accept=".jpg, .png, .bmp"
              placeholder="Tidak ada file yang dipilih"
              @change="upload"
            />
          </b-form-group>
          <!-- <b-form-group label-cols-md="4" label="Foto Isu Bersangkutan">
            <b-form-file
              id="extension2"
              v-model="datafile2"
              accept=".jpg, .png, .bmp"
              placeholder="Tidak ada file yang dipilih"
              @change="upload2"
            />
          </b-form-group> -->
          <b-form-group label-cols-md="4" label="Foto KTP">
            <b-form-file
              id="extension3"
              v-model="datafile3"
              accept=".jpg, .png, .bmp"
              placeholder="Tidak ada file yang dipilih"
              @change="upload3"
            />
          </b-form-group>
          <b-form-group label-cols-md="4" label="Foto STNK">
            <b-form-file
              id="extension4"
              v-model="datafile4"
              accept=".jpg, .png, .bmp"
              placeholder="Tidak ada file yang dipilih"
              @change="upload4"
            />
          </b-form-group>
          <b-form-group label-cols-md="4" label="Foto SIM">
            <b-form-file
              id="extension5"
              v-model="datafile5"
              accept=".jpg, .png, .bmp"
              placeholder="Tidak ada file yang dipilih"
              @change="upload5"
            />
          </b-form-group>
          <b-form-group label-cols-md="4" label="Tempat Perawatan" v-if="fieldForm.vendor">
            <b-row>
              <b-col cols="9">
                <form-v-select
                  ref="workshop"
                  v-model="fieldForm.workshop"
                  :dataurl="'vendor/'+fieldForm.vendor.id+'/workshop'"
                  :selected="fieldForm.workshop"
                  label=""
                  rules="required"
                  placeholder="Select Tempat Perawatan"
                  @input="changeWorkshop"
                />
              </b-col>
              <b-col cols="3">
                <b-button
                  variant="primary"
                  type="button"
                  @click.prevent="showModalWorkshop"
                >
                  <span>Tambah</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label-cols-md="4" label="Tanggal Rencana Perawatan">
            <flat-pickr
              v-model="fieldForm.plan_date"
              class="form-control"
              placeholder="Tanggal Rencana Perawatan"
            />
          </b-form-group>
          </b-col>
          <!-- <b-form-group label-cols-md="4" label="">
            <b-button
              class="mr-1"
              variant="primary"
              type="button"
              @click.prevent="validationForm"
            >
              <span v-if="loading">
                <b-spinner small />
                Loading...
              </span>
              <span v-else>Simpan</span>
            </b-button>
            <b-button class="mr-1" variant="warning" type="button" @click.prevent="batal">
              <span>Batal</span>
            </b-button>
          </b-form-group> -->
          
          </b-card>
        </b-col>
        
        <div class="col-sm-12" v-if="$route.params.id == null">
          <b-card title="Foto Isu Bersangkutan">
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <b-form-file
                      id="fileIssuePhotos"
                      v-model="fileIssuePhotos"
                      placeholder="Tidak ada file yang dipilih"
                      style="display: none"
                      @change="selectFileIssuePhotos"
                    />
                    <b-button
                      class="mr-1"
                      variant="primary"
                      type="submit"
                      @click.prevent="uploadIssuePhoto()"
                    >
                      <span>Tambah Foto</span>
                    </b-button>
                  
                  </b-col>
                  <br>
                </b-row>
              </b-form>
            <br>
            <b-table
              striped
              responsive
              :items="issuePhotos"
              :fields="fieldIssuePhotos"
            >
              <template #cell(photo)="data">
                <img :src="data.item.photo_url" :alt="data.item.photo_url" width="200px">
              </template>
              <template #cell(_)="data">
                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-1 btn-icon"
                  @click="deleteIssuePhotos(data)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
          </b-card>
        </div>
        <div class="col-sm-12" v-if="$route.params.id == null">
          <b-card title="Dokumen Isu Bersangkutan">
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <b-form-file
                      id="fileIssueDocument"
                      v-model="fileIssueDocument"
                      placeholder="Tidak ada file yang dipilih"
                      style="display: none"
                      @input="selectFileIssueDocument"
                    />
                    <b-button
                      class="mr-1"
                      variant="primary"
                      type="submit"
                      @click.prevent="uploadIssueDocument()"
                    >
                      <span>Tambah Dokumen</span>
                    </b-button>
                  
                  </b-col>
                  <br>
                </b-row>
              </b-form>
            <br>
            <b-table
              striped
              responsive
              :items="issueDocument"
              :fields="fieldIssueDocument"
            >
              <template #cell(_)="data">
                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-1 btn-icon"
                  @click="deleteDocument(data)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
            <b-form-group label-cols-md="0" label="">
              <b-button
                class="mr-1"
                variant="primary"
                type="button"
                @click.prevent="validationForm"
              >
                <span v-if="loading">
                  <b-spinner small />
                  Loading...
                </span>
                <span v-else>Simpan</span>
              </b-button>
              <b-button class="mr-1" variant="warning" type="button" @click.prevent="batal">
                <span>Batal</span>
              </b-button>
            </b-form-group>
          </b-card>
        </div>
      </b-row>
    </b-form>
    <b-modal
      ref="modal-workshop"
      centered
      title="Tambah Bengkel"
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <div><baseform
        :fields="fields"
        :reff-fields="reff_fields"
        :posturl="posturl"
        :ismodal="true"
        @onsubmite="submitModal"
      /></div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BBadge,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BTable,
  BModal
} from 'bootstrap-vue'
import FormInput from '@/views/base/form/FormInput.vue'
import FormRadio from '@/views/base/form/FormRadio.vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FormAutocomplate from '@/views/base/form/FormAutocomplate.vue'
import baseform from '@/views/base/BaseForm.vue'
import Cleave from 'vue-cleave-component'


export default {
  components: {
    BCard,
    BCol,
    BRow,
    BForm,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BTable,
    FormInput,
    FormRadio,
    vSelect,
    BFormCheckbox,
    BSpinner,
    flatPickr,
    FormVSelect,
    FormAutocomplate,
    baseform,
    BModal,
    Cleave
  },
  data() {
    return {
      loading: false,
      optionVariant: [],
      optionVendor: [],
      datafile1: null,
      datafile2: null,
      datafile3: null,
      datafile4: null,
      datafile5: null,
      idForm: null,
      maintenance_id:null,
      fieldForm: {
        vehicle: null,
        variant: null,
        vehicle_id: null,
        subject: null,
        description: null,
        odometer: 0,
        odometer_evidence: null,
        issue_evidence: null,
        ktp_evidence:null,
        stnk_evidence:null,
        company_id: null,
        company_name:null,
        company_address:null,
        date:moment().format('YYYY-MM-DD'),
        plan_date:moment().format('YYYY-MM-DD'),
        name:null,
        address:null,
        issue_address:null,
        email:null,
        phone:null,
        nik:null,
        guarantor_type:"impactor",
        guarantor_id: null,
        issue_photos: [],
        workshop:null,
        vendor:null
      },
      data_form: {
        file_type_id: '',
        data_file: null,
      },
      data_form2: {
        file_type_id: '',
        data_file: null,
      },
      data_form3: {
        file_type_id: '',
        data_file: null,
      },
      data_form4: {
        file_type_id: '',
        data_file: null,
      },
      data_form5: {
        file_type_id: '',
        data_file: null,
      },
      assurance: {
        name: '',
        address: '',
        no_telp_hp: '',
        email: '',
      },
      impactor: {
        name: '',
        address: '',
        no_ktp_sim: '',
        no_telp_hp: '',
        number_plate: '',
        merk: '',
        tahun: '',
        warna: '',
      },
      issuePhotos: [],
      fieldIssuePhotos: [
        { key: 'photo', label: 'File Foto'},
        '_'
      ],
      fileIssuePhotos: null,
      issueDocument: [],
      fieldIssueDocument: [
        { key: 'name', label: 'File Document'},
        '_'
      ],
      fileIssueDocument: null,
      posturl:'/workshop',
      fields:[
        { key:'code', label:'Kode', placeholder:'Kode Bengkel', type: 'input',rules:'required' },
        { key:'name', label:'Nama', placeholder:'Nama Bengkel', type: 'input',rules:'required' },
        { key:'address', label:'Alamat', placeholder:'Alamat',  type: 'autocomplate',rules:'required' },
        { type:'map', label:'', ref:'address'},
        { key:'city', label:'Kota', placeholder:'Masukkan Kota', type: 'autocomplate', indexValue:'name', objectView:'data', dataurl:'/city?search=' },
        { key:'postal_code', label:'Kode pos', placeholder:'Kode Pos',  type: 'input',rules:'' },
        { key:'email', label:'Email', placeholder:'Email',  type: 'input',rules:'required|email' },
        { key:'phone', label:'Telephone', placeholder:'Telephone',  type: 'input',rules:'' },
        { key:'hr'},
        { key:'cp_name', label:'Contact Person', placeholder:'Nama Contact Person', type: 'input',rules:'required' },
        { key:'cp_number', label:'Telephone Contact Person', placeholder:'Telephone', type: 'input',rules:'' },
        { key:'cp_email', label:'Email Contact Person', placeholder:'Email', type: 'input',rules:'' },
        { key:'hr'},
        { key:'register_date', label:'Tanggal Register',  type: 'date',rules:'', val:'now' },
      ],
      reff_fields:[],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },
  mounted() {
    this.getVehicle()
    this.getVendor()
    if (this.$route.params.id != null) {
      this.$http.get('/issue/' + this.$route.params.id).then(res => {
        this.idForm = res.data.id
        this.maintenance_id = res.data.maintenance_id
        this.fieldForm = {
          vehicle: res.data.vehicle,
          variant: {
            id: res.data.vehicle.id,
            name: res.data.vehicle.number,
          },
          vehicle_id: res.data.vehicle.id,
          subject: res.data.subject,
          description: res.data.description,
          odometer: res.data.odometer,
          name:res.data.name,
          address:res.data.address,
          phone:res.data.phone,
          email:res.data.email,
          nik:res.data.nik,
          company_id:res.data.company_id,
          company_name:res.data.company_name,
          company_address:res.data.company_address,
          issue_address:res.data.issue_address,
          date:res.data.date,
          guarantor_type: res.data.guarantor_type,
          guarantor_id: res.data.guarantor_id,
        }
        if (res.data.guarantor_type == 'impactor')
        {
          this.impactor = {
            name: res.data.issue_guarantor.name,
            address: res.data.issue_guarantor.address,
            no_ktp_sim: res.data.issue_guarantor.no_ktp_sim,
            no_telp_hp: res.data.issue_guarantor.no_telp_hp,
            number_plate: res.data.issue_guarantor.number_plate,
            merk: res.data.issue_guarantor.merk,
            tahun: res.data.issue_guarantor.tahun,
            warna: res.data.issue_guarantor.warna,
          }
        }
        else{
          this.assurance = {
            name: res.data.issue_guarantor.name,
            address: res.data.issue_guarantor.address,
            no_telp_hp: res.data.issue_guarantor.no_telp_hp,
            email: res.data.issue_guarantor.email,
          }
        }
      })
    }
  },
  methods: {
    getVehicle() {
      // let datas = []
      // this.$http.get('vehicle?length=').then(res => {
      //   for (var vehicle of res.data.data) {
      //     if (vehicle.vehicle)
      //     {
      //         datas.push({ id: vehicle.id, name: vehicle.number });
      //     }
      //   }
      // })
      // .then(() => {
      //   this.optionVariant = datas
      //   // console.log(this.optionVariant)
      // })
    },
    getVendor() {
      this.$http.get('vendor?length=').then(res => {
        this.optionVendor = res.data.data
      })
    },
    async validationForm() {
      this.loading = true
      if (this.fieldForm.guarantor_type == 'assurance'){
        this.fieldForm.issue_guarantor = this.assurance;
      }
      else{
        this.fieldForm.issue_guarantor = this.impactor;
      }
      if (this.$route.params.id == null) {
        let formData = new FormData()
        let formData2 = new FormData()
        let formData3 = new FormData()
        let formData4 = new FormData()
        let formData5 = new FormData()
        formData.append('file', this.data_form.data_file)
        formData2.append('file', this.data_form2.data_file)
        formData3.append('file', this.data_form3.data_file)
        formData4.append('file', this.data_form4.data_file)
        formData5.append('file', this.data_form5.data_file)
        if(this.data_form3.data_file){
          let ktp = await this.$http.post('/upload/image', formData3)
          if(ktp.data.path != undefined){
            this.fieldForm.ktp_evidence = ktp.data.path
          }
        }
        
        if(this.data_form4.data_file){
          let stnk = await this.$http.post('/upload/image', formData4)
          if(stnk.data.path != undefined){
            this.fieldForm.stnk_evidence = stnk.data.path
          }
        }

        if(this.data_form5.data_file){
          let sim = await this.$http.post('/upload/image', formData5)
          if(sim.data.path != undefined){
            this.fieldForm.sim_evidence = sim.data.path
          }
        }

        if(!this.data_form.data_file){
          var error = {response:{data:{message:''}}}
          error.response.data.message = 'Foto Odometer Tidak Boleh Kosong'
          return this.errorSubmit(error)
        }

        // if(!this.data_form2.data_file){
        //   var error = {response:{data:{message:''}}}
        //   error.response.data.message = 'Foto Isu Tidak Boleh Kosong'
        //   return this.errorSubmit(error)
        // }

        let issue_photos_upload = []

        for (let issuePhoto of this.issuePhotos){
          let formData = new FormData()
          formData.append('file', issuePhoto.photo)
          let filename = ""
          let url = 'upload/image'

          let resFile = await this.$http.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}}).catch(err=>{ this.errorSubmit(err)})
          if(resFile){
            if(resFile.data.path.split("/"))
              filename = resFile.data.path.split("/").pop()
            
            let photo = {
              'photo': resFile.data.path,
              'filename': filename
            }
            issue_photos_upload.push(photo)
          }
        }

        this.fieldForm.issue_photos = issue_photos_upload

        let issue_document_upload = []

        for (let issueDocument of this.issueDocument){
          let formData = new FormData()
          formData.append('file', issueDocument.file)
          let filename = ""
          let url = 'upload/file'

          let resFile = await this.$http.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
              .catch(err=>{ this.errorSubmit(err)})
          if(resFile){
            if(resFile.data.path.split("/"))
              filename = resFile.data.path.split("/").pop()
            
            let file = {
              'file': resFile.data.path,
              'filename': filename,
              'name':issueDocument.name
            }
            issue_document_upload.push(file)
          }
        }

        this.fieldForm.issue_document = issue_document_upload

        this.$http
        .post('/upload/image', formData)
        .then(res => {
          if (res.data.path != undefined) {
              this.fieldForm.odometer_evidence = res.data.path
            }
          this.$http
            .post('/issue', this.fieldForm)
            .then(res => {
              this.idForm = res.data.data.id
              this.maintenance_id = res.data.data.maintenance_id
              this.successSubmit()
            })
            .catch(err => {
              this.errorSubmit(err)
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch(err => {
          this.errorSubmit(err)
        })

        this.$http
          .post('/upload/image', formData)
          .then(res => {
            if (res.data.path != undefined) {
              this.fieldForm.odometer_evidence = res.data.path
            }
            
          })
          .catch(err => {
            this.errorSubmit(err)
          })
      } else {
        this.fieldForm.company_id = this.fieldForm.company.id;
        let formData = new FormData()
        let formData2 = new FormData()
        let formData3 = new FormData()
        let formData4 = new FormData()
        let formData5 = new FormData()
        formData.append('file', this.data_form.data_file)
        formData2.append('file', this.data_form2.data_file)
        formData3.append('file', this.data_form3.data_file)
        formData4.append('file', this.data_form4.data_file)
        formData5.append('file', this.data_form5.data_file)
        if (this.datafile1 == null && this.datafile2 == null) {
          this.$http
            .put('/issue/' + this.$route.params.id, this.fieldForm)
            .then(() => {
              this.successSubmit()
            })
            .catch(err => {
              this.errorSubmit(err)
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          if(this.data_form3.data_file){
            let ktp = await this.$http.post('/upload/image', formData3)
            if(ktp.data.path != undefined){
              this.fieldForm.ktp_evidence = ktp.data.path
            }
          }
          
          if(this.data_form4.data_file){
            let stnk = await this.$http.post('/upload/image', formData4)
            if(stnk.data.path != undefined){
              this.fieldForm.stnk_evidence = stnk.data.path
            }
          }

          if(this.data_form5.data_file){
            let sim = await this.$http.post('/upload/image', formData5)
            if(sim.data.path != undefined){
              this.fieldForm.sim_evidence = sim.data.path
            }
          }

          if(!this.data_form.data_file){
            var error = {response:{data:{message:''}}}
            error.response.data.message = 'Foto Odometer Tidak Boleh Kosong'
            return this.errorSubmit(error)
          }

          // if(!this.data_form2.data_file){
          //   var error = {response:{data:{message:''}}}
          //   error.response.data.message = 'Foto Isu Tidak Boleh Kosong'
          //   return this.errorSubmit(error)
          // }

          this.$http
          .post('/upload/image', formData)
          .then(res => {
            if (res.data.path != undefined) {
              this.fieldForm.odometer_evidence = res.data.path
            }
            this.$http
              .put('/issue/' + this.$route.params.id, this.fieldForm)
              .then(() => {
                this.successSubmit()
              })
              .catch(err => {
                this.errorSubmit(err)
              })
              .finally(() => {
                this.loading = false
              })
          })
          .catch(err => {
            this.errorSubmit(err)
          })
        }
      }
    },
    changeSelectVariant(data) {
      if (data != null) {
        this.fieldForm.vehicle_id = data.id
        this.fieldForm.variant.id = data.id
        this.fieldForm.variant.name = data.name
      }
      this.changeGuarantor();
    },
    uploadIssuePhoto() {
      document.getElementById('fileIssuePhotos').click();
    },
    uploadIssueDocument() {
      document.getElementById('fileIssueDocument').click();
    },
    selectFileIssuePhotos(data){
      if (data){
        let url_photo = URL.createObjectURL( data.target.files[0]);
        this.issuePhotos.push({'photo': data.target.files[0], 'photo_url': url_photo});
      }
    },
    selectFileIssueDocument(data){
      if (data){
        let ext = data.name?data.name.split(".").pop():""
        let arr_file = ["docx", "doc", "pdf", "xls", "xlsx"]
        if(arr_file.includes(ext) && ext == 'pdf'){
          let url_file = URL.createObjectURL( data);
          this.issueDocument.push({'file': data, 'file_url': url_file, 'name':data.name});
        }
        else
          return this.$bvToast.toast('The file must be a file of type: pdf.', {
            title: 'Error',
            solid: true,
            variant:'danger'
          })
      }
    },
    deleteIssuePhotos(data){
      this.issuePhotos.forEach((issueFile, key) => {
        if (issueFile.photo_url == data.item.photo_url){
          this.issuePhotos.splice(key, 1);
        }
      })

    },
    upload(e) {
      let files = e.target.files[0]
      this.data_form.data_file = files
    },
    upload2(e) {
      let files = e.target.files[0]
      this.data_form2.data_file = files
    },
    upload3(e) {
      let files = e.target.files[0]
      this.data_form3.data_file = files
    },
    upload4(e) {
      let files = e.target.files[0]
      this.data_form4.data_file = files
    },
    upload5(e) {
      let files = e.target.files[0]
      this.data_form5.data_file = files
    },
    successSubmit() {
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      if (this.ismodal) {
        this.$emit('onsubmite', true)
      } else {
        // this.$router.push({ name: 'issue-kendaraan-detail', params: { id: this.idForm } })
        if(this.maintenance_id)
          this.$router.push({ name: 'perawatan-detail', params:{id:this.maintenance_id} })
        else
          this.batal()
      }

      this.$store.dispatch('sendMessage', '1')
    },
    errorSubmit(err) {
      const msg = err.response.data.message
      this.$bvToast.toast(msg ? msg : 'Submit error', {
        title: 'Error',
        solid: true,
        variant: 'danger',
      })
    },
    batal() {
      this.$router.go(-1)
    },
    changeGuarantor() {
      if (this.fieldForm.variant != null){
        this.$http.get('vehicle/'+this.fieldForm.variant.id).then(res => {
          this.assurance.name = res.data.vehicle.assurance.name;
          this.assurance.address = res.data.vehicle.assurance.address;
          this.assurance.no_telp_hp = res.data.vehicle.assurance.phone;
          this.assurance.email = res.data.vehicle.assurance.email;
          this.fieldForm.company_id = res.data.vehicle.spb.region.id;
          this.fieldForm.company_name = 'PT Haleyora Power ' + res.data.vehicle.spb.region.name;
          this.fieldForm.company_address = res.data.vehicle.spb.region.address;
        })
      }
      else {
        this.assurance.name = '';
        this.assurance.address = '';
        this.assurance.no_telp_hp = '';
        this.assurance.email = '';
        this.fieldForm.company_id = '';
        this.fieldForm.company_name = '';
        this.fieldForm.company_address = '';
      }
    },
    changeInput(val){
      console.log(val)
      this.fieldForm.vendor = null
      this.fieldForm.workshop = null
      if (val) {
        this.fieldForm.variant = val
        this.fieldForm.vehicle_id = val.id
        this.fieldForm.variant.id = val.id
        this.fieldForm.variant.name = val.name
        this.$nextTick().then(() => {
          this.fieldForm.vendor = val.vendor
          this.setForm(val.vendor.id)
        })
        
      }
      this.changeGuarantor();
    },
    changeWorkshop(data){
      if (data != null) {
        this.fieldForm.workshop_id = data.id
      }else{
        this.fieldForm.workshop_id = null
      }
    },
    submitModal(){
      let vendor = this.fieldForm.vendor
      this.fieldForm.vendor = null
      this.$nextTick().then(() => {
        this.fieldForm.vendor = vendor
        this.setForm(vendor.id)
      })
      this.$refs['modal-workshop'].hide()
    },
    setForm(vendor_id){
      this.reff_fields = [
        { key:'vendor_id', value:vendor_id }
      ]
    },
    showModalWorkshop(){
      this.$refs['modal-workshop'].show()
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
