<template>
  <div>
    <!-- <validation-observer ref="asd"> -->

    <b-form-group
      label-cols-md="4"
      :label="label"
      :description="desc"
    >
      <validation-provider
        #default="{ errors }"
        :name="label"
        :rules="rules"
      >
        <b-form-radio-group
          :checked="value"
          :options="options"
          value-field="id"
          text-field="name"
          disabled-field="disabled"
          @input="handleInput"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- </validation-observer> -->
  </div>
</template>

<script>
import {BFormRadioGroup, BFormGroup} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
  export default {
    components: {
      BFormRadioGroup,
      BFormGroup,
      ValidationProvider,
    },
    props: {
      value: {
        type: [String, Number],
        default:''
      },
      label: {
        type: String,
        default: 'LabelName'
      },
      placeholder: {
        type: String,
        default: ''
      },
      desc: {
        type: String,
        default: ''
      },
      rules: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: ()=>[]
      },
    },
    methods: {
      handleInput(e) {
       // console.log(e)
        this.$emit('input', e)
      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>
